<template>
  <v-container class="pl-sm-16" fluid>
    <v-data-table
      :headers="headers"
      :items="fishes"
      :search="search"
      :loading="api.isLoading"
      loading-text="Loading... Please wait"
      class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="teal--text"> Fish CRUD </v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="500px" scrollable>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                New Item
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="f3-title-bar">
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12" class="pb-0">
                      <v-text-field
                        v-model="editedItem.name_en"
                        label="Name (en)"
                        filled
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pb-0">
                      <v-text-field
                        v-model="editedItem.name_ta"
                        label="Name (ta)"
                        filled
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pb-0">
                      <v-text-field
                        v-model="editedItem.name_si"
                        label="Name (si)"
                        filled
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="pb-0">
                      <v-text-field
                        v-model="editedItem.grade"
                        label="Grade"
                        filled
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" sm="6" class="pb-0">
                      <v-text-field
                        v-model="editedItem.min_size"
                        label="Min-size"
                        filled
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" class="pb-0">
                      <v-text-field
                        v-model="editedItem.image_url"
                        label="Image URI"
                        filled
                        dense
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" v-if="editedItem.image_url" class="pb-0">
                      <v-img :src="editedItem.image_url"></v-img>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions class="f3-action-bar">
                <v-spacer></v-spacer>
                <v-btn color="red lighten-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="teal darken-4" text @click="save"> Save </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="280px">
            <v-card>
              <v-card-title>
                Are you sure you want to delete this item?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="teal darken-1" text @click="closeDelete"
                  >Cancel</v-btn
                >
                <v-btn color="red darken-1" text @click="deleteItemConfirm"
                  >Yes</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-card-title>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
            class="mb-3"
          ></v-text-field>
        </v-card-title>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
        <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
      </template>
      <template v-slot:item.image_url="{ item }">
        <v-avatar size="40" tile>
          <v-img :src="item.image_url" alt="Image"></v-img>
        </v-avatar>
      </template>
    </v-data-table>
    <v-snackbar v-model="api.isError" :timeout="timeout" text>
      {{ api.error }}
      <template v-slot:action="{ attrs }">
        <v-btn color="teal" text v-bind="attrs" @click="api.isError = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>
      
  <script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  }),
  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  created() {
    this.initialize();
  },
  props: [
    //
  ],
  data: () => ({
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    timeout: 2000,
    dialog: false,
    dialogDelete: false,
    search: "",
    headers: [
      { text: "Image", value: "image_url", sortable: false },
      { text: "Name (en)", value: "name_en" },
      { text: "Name (ta)", value: "name_ta" },
      { text: "Name (si)", value: "name_si" },
      { text: "Grade", value: "grade" },
      { text: "Min-Size", value: "min_size" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    fishes: [],
    editedIndex: -1,
    editedItem: {
      name_en: "",
      name_ta: "",
      name_si: "",
      grade: "",
      min_size: "",
      image_url: "",
    },
    defaultItem: {
      name_en: "",
      name_ta: "",
      name_si: "",
      grade: "",
      min_size: "",
      image_url: "",
    },
  }),
  mounted() {
    //
  },
  methods: {
    initialize() {
      this.api.url =
        this.$api.servers.bidding +
        "/v1/en/admin/fish/all";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };

      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = (resp) => {
        this.api.isLoading = false;
        resp.forEach((element) => {
          this.fishes.push({
            id: element.id,
            name_en: JSON.parse(element.names).en,
            name_ta: JSON.parse(element.names).ta,
            name_si: JSON.parse(element.names).si,
            grade: element.grade,
            min_size: element.min_size,
            image_url: element.image_url,
          });
        });
      };
      this.api.method = "get";
      this.$api.fetch(this.api);
    },

    update() {
      this.api.url =
        this.$api.servers.bidding +
        "/v1/en/admin/fish/update";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };

      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = () => {
        this.api.isLoading = false;
      };
      this.api.params = {
        id: this.editedItem.id,
        names: JSON.stringify({
          en: this.editedItem.name_en,
          ta: this.editedItem.name_ta,
          si: this.editedItem.name_si,
        }),
        grade: this.editedItem.grade,
        min_size: this.editedItem.min_size,
        image_url: this.editedItem.image_url,
      };
      this.api.method = "post";
      this.$api.fetch(this.api);
    },

    new() {
      this.api.url =
        this.$api.servers.bidding +
        "/v1/en/admin/fish/new";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };

      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = () => {
        this.api.isLoading = false;
      };
      this.api.params = {
        names: JSON.stringify({
          en: this.editedItem.name_en,
          ta: this.editedItem.name_ta,
          si: this.editedItem.name_si,
        }),
        grade: this.editedItem.grade,
        min_size: this.editedItem.min_size,
        image_url: this.editedItem.image_url,
      };
      this.api.method = "post";
      this.$api.fetch(this.api);
    },

    delete() {
      this.api.url =
        this.$api.servers.bidding +
        "/v1/en/admin/fish/delete";

      this.api.callbackReset = () => {
        this.api.isLoading = true;
        this.api.isError = false;
      };

      this.api.callbackError = (e) => {
        this.api.isLoading = false;
        this.api.isError = true;
        this.api.error = e;
      };
      this.api.callbackSuccess = () => {
        this.api.isLoading = false;
      };
      this.api.params = {
        id: this.editedItem.id,
      };
      this.api.method = "post";
      this.$api.fetch(this.api);
    },

    editItem(item) {
      this.editedIndex = this.fishes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.fishes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.delete();
      this.fishes.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedIndex > -1) {
        this.update();
        Object.assign(this.fishes[this.editedIndex], this.editedItem);
      } else {
        this.new();
        this.fishes.push(this.editedItem);
      }
      this.close();
    },
  },
};
</script>

<style>
.name-card {
  background: rgb(1, 128, 161);
  background: linear-gradient(
    90deg,
    rgba(1, 129, 161, 0.8) 0%,
    rgba(12, 186, 189, 0.8) 100%
  );
  color: white !important;
}

.user-card {
  background: rgb(1, 128, 161);
  background: linear-gradient(
    90deg,
    rgba(1, 129, 161, 0.5) 0%,
    rgba(12, 186, 189, 0.5) 100%
  );
}

.aution-icon {
  color: rgba(255, 255, 255, 0.7) !important;
  font-size: 50px !important;
  position: absolute !important;
}
</style>